import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import { Modal } from "react-bootstrap";
import { Country, State, City } from "country-state-city";


// import lib
import fileObjectUrl from "../../lib/fileObjectUrl";
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

// import api
import { updateProfile } from "../../api/users";

//import validation
import { ProfileValidation } from "./validation";


const initialFormValue = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  country: "",
  countryCode: "",
  postalCode: "",
  phoneCode: "",
  phoneNo: "",
  profile: ""
}
let imageType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']

const ProfileModal = (props) => {

  //props
  const { isShow, onHide } = props;


  // state
  const [formValue, setFormValue] = useState(initialFormValue)
  const { firstName, lastName, profile, address, city, country, countryCode, postalCode, phoneCode, phoneNo } = formValue
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  // redux-state
  const accountData = useSelector((state) => state.account);



  const countryArr = [];

  const countryDetails = Country.getAllCountries();
  countryDetails.map((data, i) => {
    countryArr.push({
      value: data.isoCode,
      label: data.name,
    });
  });


  const handleCountry = (e) => {
    const { name, value } = e.target;
    const getCountry = countryArr.find((el) => el.value == value);
    let formData = { ...formValue, ...{ ["country"]: getCountry.label, ["countryCode"]: getCountry.value } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  //function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "firstName" || name == "lastName" || name == 'city') {
      if (/[^a-zA-Z ]/.test(value)) return;
    }
    if (name == "postalCode") {
      if (/[^a-zA-Z0-9]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
  }

  const handleFile = async (e) => {
    const { name, files } = e.target;
    if (!imageType.includes(files[0].type.split('/')[1])) {
      return toastAlert("error", 'Invalid image format', "updateProfile");

    }
    if (files[0]?.size >= 2000000) {
      return toastAlert("error", "Upload image cannot more then 2MB!", "updateProfile");
    }

    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };


  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData, ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        }
      }
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } }
    }
    setFormValue(formData)
  }

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let reqData = {
        firstName,
        lastName,
        profile,
        address,
        city,
        country: formValue.country,
        postalCode,
        phoneCode,
        phoneNo
      };
      let validationError = ProfileValidation(reqData);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }
      const formData = new FormData();
      formData.append("profile", profile);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", formValue.country);
      formData.append("countryCode", formValue.countryCode);
      formData.append("postalCode", postalCode);
      formData.append("phoneCode", phoneCode);
      formData.append("phoneNo", phoneNo);
      const { status, loading, message, errors } = await updateProfile(
        formData,
        dispatch
      );
      setLoader(loading);

      if (status === "success") {
        toastAlert("success", message, "updateProfile");
        hanldeClose();
      } else {
        errors && setErrors(errors);
      }
    } catch (err) {
      console.log(err, 'erroror')
    }
  };

  const fetchFunction = () => {
    if (accountData) {
      let formData = {
        firstName: accountData?.firstName,
        lastName: accountData?.lastName,
        address: accountData?.address,
        country: accountData?.country,
        countryCode: accountData?.countryCode,
        city: accountData?.city,
        postalCode: accountData?.postalCode,
        profile: accountData?.profile,
        phoneCode: accountData?.phoneCode,
        phoneNo: accountData?.phoneNo,
      };
      setFormValue({ ...formValue, ...formData });
    }
  }


  const hanldeClose = () => {
    setErrors({});
    fetchFunction()
    onHide()
  }


  useEffect(() => {
    fetchFunction()
  }, [accountData])

  // console.log(formValue, 'formVlaue')

  return (
    <Modal
      show={isShow}
      onHide={hanldeClose}
      backdrop="static"
      keyboard={false}
      size="md"
      centered >
      <Modal.Header closeButton >
        <Modal.Title>  <h4 >
          Update Profile
        </h4></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="assets_form">
          <div className="mb-3">
            <label for="profile" className="form-label">
              Profile Picture (Optional)
            </label>
            <span className={"text-danger"}>
              {errors && errors.profile}
            </span>
            <img
              src={fileObjectUrl(profile)}
              style={{ width: "86px" }}
              className="me-2"
            />
            <div class="custom_file_upload btn">
              Upload
              <input
                type="file"
                name="profile"
                onChange={handleFile}
              />
            </div>
            <div className="form-text ms-1">
              Max. upload file size 2MB (Jpeg, Png, Jpg only).
            </div>
            {profile?.name &&
              <div className="uploaded_files_row">
                <div>
                  <i className="bi bi-file-earmark-plus"></i>
                  <span>{profile?.name}</span>
                </div>
                <div>
                  <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['profile']: "" } })}></i>
                </div>
              </div>
            }
          </div>
          <div className="mb-3">
            <label for="firstname" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control formcontrol2 primary_asset_inp"
              name="firstName"
              value={firstName}
              onChange={handleChange}
              placeholder="Enter First Name"
            />
            <span className={"text-danger"}>
              {errors && errors.firstName}
            </span>
          </div>
          <div className="mb-3">
            <label for="lastname" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control formcontrol2  primary_asset_inp"
              name="lastName"
              value={lastName}
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
            <span className={"text-danger"}>
              {errors && errors.lastName}
            </span>
          </div>
          <div className="mb-3 phone_num">
            <label for="lastname" className="form-label">
              Phone Number
            </label>
            <PhoneInput
            className="phone-number"
              placeholder="Enter phone number"
              value={phoneCode + phoneNo}
              onChange={handlePhoneNumber}
              specialLabel={false}
              enableSearch={true}
            />
            <span className={"text-danger"}>
              {errors && errors.phoneCode}
            </span>
          </div>
          <div className="mb-3">
            <label for="address" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="form-control formcontrol2 primary_asset_inp"
              name="address"
              value={address}
              onChange={handleChange}
              placeholder="Enter address"
            />
            <span className={"text-danger"}>
              {errors && errors.address}
            </span>
          </div>
          <div className="mb-3">
            <label for="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control formcontrol2 primary_asset_inp"
              name="city"
              value={city}
              onChange={handleChange}
              placeholder="Enter city"
            />
            <span className={"text-danger"}>
              {errors && errors.city}
            </span>
          </div>
          <div className="mb-3">
            <label for="country" className="form-label">
              Country
            </label>
            {/* <input
              type="text"
              className="form-control primary_asset_inp"
              name="country"
              value={country}
              onChange={handleChange}
              placeholder="Enter country"
            /> */}
            <select
              name="countryCode"
              value={countryCode}
              onChange={handleCountry}
              className="form-control formcontrol2 primary_asset_select"

            >
              <option value={""}>Select country</option>
              {countryArr.map((data, i) => {
                return (
                  <option value={data.value}>
                    {data.label}
                  </option>
                );
              })}
            </select>
            <span className={"text-danger"}>
              {errors && errors.country}
            </span>
          </div>
          <div className="mb-3">
            <label for="postal" className="form-label">
              Postal / Zip Code
            </label>
            <input
              type="text"
              className="form-control primary_asset_inp"
              name="postalCode"
              value={postalCode}
              onChange={handleChange}
              placeholder="Enter postalCode"
            />
            <span className={"text-danger"}>
              {errors && errors.postalCode}
            </span>
          </div>
          <div className="asset_modal_btn_grp mt-4 mb-4">
            <button
              className="w-100 primary_btn primary_btn_transform"
              type="button"
              onClick={hanldeClose}
            >
              Cancel
            </button>
            <button
              className="w-100 primary_btn"
              onClick={handleSubmit}
              type="button"
            >
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;
