import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from "../ThemeContext";

const TradingViewWidget = ({ symbols }) => {
  const { theme } = useTheme();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: symbols,
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: 'adaptive',
      colorTheme: theme,
      locale: 'en',
    });

    const widgetContainer = document.getElementById('tradingview-widget-container');
    if (widgetContainer) {
      widgetContainer.appendChild(script);
    }
    
    return () => {
      const widgetContainer = document.getElementById('tradingview-widget-container');
      if (widgetContainer) {
        widgetContainer.innerHTML = '';
      }
    };
  }, [symbols, theme]);

  return (
    <div className="tradingview-widget-container" id="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
};

TradingViewWidget.propTypes = {
  symbols: PropTypes.arrayOf(
    PropTypes.shape({
      proName: PropTypes.string.isRequired,
    })
  ).isRequired
};

export default TradingViewWidget;
