import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//import api
import { getAssetData } from "../../api/walletAction";

//import component
import WalletDetails from "./WalletDetails";

const Wallet = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    getAssetData(dispatch)
  }, [])

  return (
    <>
      <div className="innerpages_wrapper">
        <div className="container">
          {/* <div className="inner_title_wrapper">
            <h2 className="inner_title">Wallet</h2>
          </div> */}
          <div className="dash_wrapper dashwrapper1">
            <WalletDetails />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
