import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useSelector } from "react-redux"

//import api
import { getStaking } from "../../api/staking";

//import lib
import isEmpty from "../../lib/isEmpty";
import { interestByDays } from "../../lib/calculation";
import { toFixedDown } from "../../lib/roundOf";

const StakingCal = () => {

    //state
    const [selectedOption, setSelectedOption] = useState({});
    const [stakeData, setStakeData] = useState([])
    const [amount, setAmount] = useState(0)
    const [USDValue, setUSD] = useState(0)
    const [interest, setInterest] = useState(0)
    const [activeStakingMethod, setActiveStakingMethod] = useState("flexible");
    const [activeDepositTerm, setActiveDepositTerm] = useState('90');
    const [fixedAPY, setfixedAPY] = useState();
    const [coin, setCoin] = useState("")
    const [list, setList] = useState([])
    const [interUSD, setInterUSD] = useState(0)
    const [ApyData, setApyData] = useState([])

    //redux-state
    const priceConversion = useSelector((state) => state.priceConversion);


    //function
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amount') {
            if (!isEmpty(value)) {
                let PriceCnv = priceConversion.find((el) =>
                    el.baseSymbol == selectedOption.value && el.convertSymbol == "USD"
                );
                let convert = !isEmpty(PriceCnv?.convertPrice) ? parseFloat(value * PriceCnv.convertPrice) : parseFloat(value);
                setUSD(toFixedDown(convert, 4))
            }
            else if (isEmpty(value)) {
                setUSD(0)
            }
            setAmount(value)
        }
    }


    const handleSelect = (eventKey) => {
        const newSelectedOption = list.find(
            (option) => option.value === eventKey
        );
        setCoin(eventKey)
        setSelectedOption(newSelectedOption);
        setAmount(0)
        setUSD(0)
    };


    function interestCal(Method, amount, currency, fixedAPY, activeDepositTerm) {
        let Data = stakeData && stakeData.length > 0 && stakeData.find((el) => el.coin === currency && el.type.includes(Method))
        if (Method === 'flexible') {
            let Value = interestByDays(amount, Data?.flexibleAPY, 365)
            setInterest(toFixedDown(Value, Data?.decimal))
            let PriceCnv = priceConversion.find((el) =>
                el.baseSymbol == currency && el.convertSymbol == "USD"
            );
            let priceData = !isEmpty(PriceCnv?.convertPrice) ? parseFloat(Value * PriceCnv.convertPrice) : parseFloat(Value);
            setInterUSD(toFixedDown(priceData, 4))
        } else if (Method === 'fixed') {
            let Value = (activeDepositTerm * (interestByDays(amount, fixedAPY, 365)))
            setInterest(toFixedDown(Value, Data?.decimal))
            let PriceCnv = priceConversion.find((el) =>
                el.baseSymbol == currency && el.convertSymbol == "USD"
            );
            let priceData = !isEmpty(PriceCnv?.convertPrice) ? parseFloat(Value * PriceCnv.convertPrice) : parseFloat(Value)
            setInterUSD(toFixedDown(priceData, 4))
        }
    }


    const handleStakingMethodClick = (button) => {
        setActiveStakingMethod(button);
        // setSelectedOption({})
        // setUSD(0)
    };

    const handleDepositTermClick = (buttonNumber) => {
        setfixedAPY(buttonNumber.APY)
        setActiveDepositTerm(buttonNumber.value);
    };

    const getStakeData = async () => {
        let reqData = {
            type: activeStakingMethod
        }
        let arr = []
        const { status, result } = await getStaking(reqData)
        if (status === 'success') {
            if (result && result.length > 0) {
                if (isEmpty(selectedOption)) {
                    setCoin(result[0]?.coin)
                    setSelectedOption({ label: result[0]?.image, value: result[0]?.coin })
                } else {
                    setStakeData(result)
                    result && result.length > 0 && result.map((item, i) => {
                        arr.push({
                            label: item.image,
                            value: item.coin
                        })
                        setList(arr)
                    })
                }
            }
        }
    }


    const APYfunction = (currency, type) => {
        let findData = stakeData && stakeData.find((el) => el.coin === currency && el.type.includes(type))
        let arrayData = []
        findData && findData.periodList && findData.periodList.length > 0 && findData.periodList.map((el, key) => {
            arrayData.push({
                value: el.days,
                APY: el.APY,
                label: parseInt(el.days / 30) + " month"
            })
            setApyData(arrayData)
        })
        setfixedAPY(findData?.periodList[0].APY)
    }

    useEffect(() => {
        getStakeData()
        interestCal(activeStakingMethod, amount, coin, fixedAPY, activeDepositTerm)
    }, [activeStakingMethod, amount, coin, activeDepositTerm, fixedAPY])


    useEffect(() => {
        APYfunction(coin, activeStakingMethod)
    }, [activeStakingMethod, coin])

    return (
        <>
            <section className="mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" data-aos="fade-up" data-aos-duration="1000">
                            <h2 className="main_title text-center mb-5">
                                Unlock the potential of your <span>staking rewards</span>
                            </h2>
                            <div className="staking_calc" data-aos="fade-up" data-aos-duration="1000">
                                <div class="row g-3">
                                    <div class="col-lg-6">
                                        <label class="form-label">Asset</label>
                                        <Dropdown
                                            onSelect={handleSelect}
                                            className="image_dropdown_wrapper"
                                        >
                                            <Dropdown.Toggle
                                                id="dropdown-basic"
                                                className="image_dropdown"
                                            >
                                                <Image
                                                    src={selectedOption.label}
                                                    alt={selectedOption.value}
                                                    width={38}
                                                    height={38}
                                                />
                                                {selectedOption.value}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {list.map((option, i) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={i}
                                                            eventKey={option.value}
                                                        >
                                                            <Image
                                                                src={option.label}
                                                                alt={option.value}
                                                                width={38}
                                                                height={38}
                                                            />{" "}
                                                            {option.value}
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="amount" class="form-label" placeholder="0.00">
                                            Amount
                                        </label>
                                        <input type="number" class="form-control" onChange={handleChange} value={amount} name="amount" />
                                        <div class="form-text ms-3">≈ $ {USDValue}</div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="form-label">Staking Method</label>
                                        <div className="staking_method_btngrp staking_method_btngrp1">
                                            <button
                                                className={
                                                    activeStakingMethod === "flexible"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    handleStakingMethodClick("flexible")
                                                }
                                            >
                                                Flexible
                                            </button>
                                            <button
                                                className={
                                                    activeStakingMethod === "fixed"
                                                        ? "active"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    handleStakingMethodClick("fixed")
                                                }
                                            >
                                                Fixed
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div
                                            className={
                                                activeStakingMethod === "fixed"
                                                    ? "active"
                                                    : "inactive"
                                            }
                                        >
                                            <label class="form-label">Deposit Term</label>
                                            <div className="staking_method_btngrp staking_method_btngrp1 staking_term_btngrp">
                                                {ApyData.map((button, i) => (
                                                    <button
                                                        key={i}
                                                        onClick={() => handleDepositTermClick(button)}
                                                        className={
                                                            activeDepositTerm === button.value ? "active" : ""
                                                        }
                                                    >
                                                        {button.label}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {selectedOption?.value &&
                                        <div class="col-md-12 mb-0">
                                            <div className="staking_result_div">
                                                {activeStakingMethod === 'flexible' ? <p> In 12 months you will have total of </p> :
                                                    <p>
                                                        In{" "} {parseInt(activeDepositTerm / 30) + " month"}
                                                        you will have a total of
                                                    </p>}
                                                <div>
                                                    <h3>{interest ? interest : 0} {selectedOption?.value}</h3>
                                                    <span>≈ $ {interUSD ? interUSD : 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default StakingCal;