import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import browser from "browser-detect";
import Bowser from "bowser";
import { useDispatch } from "react-redux";
import $ from "jquery"

// api
import { getGeoInfoData, login } from "../../api/users";

// lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

const initialFormValue = {
  email: "",
  password: "",
  passwordIcon: false,
};

const LoginForm = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loginHistory, setLoginHistory] = useState({});
  const [errors, setErrors] = useState({});
  const { email, password, passwordIcon, } = formValue;
  const [loader, setLoader] = useState(false)


  //function
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValue({ ...formValue, ...{ [name]: value } });
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handlePassIcon = (name) => setFormValue({ ...formValue, ... { [name]: !formValue[name] } })


  const handleSubmit = async (e) => {
    try {
      setLoader(true)
      const { status, loading, message, error } = await login(
        { email, password, loginHistory },
        dispatch
      );
      setLoader(loading)
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "signin", "TOP_CENTER");
        navigate("/wallet");
      }
      else {
        if (error) {
          setErrors(error);
          return false;
        }
        toastAlert("error", message, "signin", "TOP_CENTER");
      }
    } catch (err) { }
  };


  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      const Browser = Bowser.getParser(window.navigator.userAgent);

      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipAddress: result.ip,
        regionName: result.region,
        broswerName: `${Browser.parsedResult.browser.name}${Browser.parsedResult.browser.version}`,
        isMobile: browserResult.mobile,
        os: Browser.parsedResult.os.name,
      });
    } catch (err) { }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  $(document).on('keypress', function (e) {
    if (e.which == 13) {
      e.preventDefault()
      handleSubmit(e)
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-10 col-lg-6 m-auto">
          <div className="dash_box mb-0">
            <div className="row align-items-center">
            
              <div className="col-lg-12">
                <div className="inner_title_wrapper mt-2">
                  <h2 className="inner_title text-center">Login</h2>
                </div>
                <form className="auth_form">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Email Address"
                      onChange={handleChange}
                      value={email}
                    />
                    <span className="text-danger">{errors?.email}</span>
                  </div>
                  <div class="mb-3">
                    <label for="inputPassword" class="form-label">
                      Password
                    </label>
                    <div className="asset_deposit_grp">
                      <input
                        type={!passwordIcon ? "password" : "text"}
                        name="password"
                        className="form-control primary_asset_inp"
                        id="inputPassword"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        value={password}
                      />
                      <span className="withdrawal_type">
                        <i class={passwordIcon ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} onClick={() => handlePassIcon('passwordIcon')}></i>
                      </span>
                    </div>
                    <span className="text-danger">{errors?.password}</span>
                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                      onClick={handleSubmit}
                    >
                      {loader && <i class="fas fa-spinner fa-spin"></i>}  Login
                    </button>
                  </div>
                  <p className="text-center">
                    <Link to="/forgot-password">Forgot Password</Link>
                  </p>
                  <p class="form_note text-center mt-3">
                    Don't have an account?  <Link to="/register">Sign Up</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
