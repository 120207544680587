import React, { useEffect, useState, useContext } from "react";
import CurrencyFormat from 'react-currency-format';
import "rc-slider/assets/index.css";




//import api
import {
  getStackingEstimate
} from "../../api/staking";


// lib
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import { useSelector } from "react-redux";
import isEmpty from "../../lib/isEmpty";
import { currencyChange } from "../../lib/pairHelper";


const StackedDetail = () => {
  const { defaultcurrency } = useSelector((state) => state.account);
  const priceConversion = useSelector((state) => state.priceConversion);

  //state
  const [tradedata, setTradeData] = useState([])
  const [PriceChange, setPriceChange] = useState()


  const getStackinginfo = async (spotPairId, type) => {
    try {
      const { status, result } = await getStackingEstimate(spotPairId);
      if (status == "success") {
        // console.log(result, "--------result")
        if (result) {
          if (defaultcurrency != "USD") {
            let convetCurrency = priceConversion && priceConversion.length > 0 && priceConversion.find((el) => el.baseSymbol == "USD" && el.convertSymbol == defaultcurrency)
            if (convetCurrency?.convertPrice) {
              let amount = !isEmpty(convetCurrency?.convertPrice) ? parseFloat(convetCurrency?.convertPrice) : 1
              let data = {
                redemption: parseFloat(result?.redemption * amount),
                furueRedemption: parseFloat(result?.furueRedemption * amount),
                futureAmount: parseFloat(result?.futureAmount * amount),
                interest: parseFloat(result?.interest * amount),
                futureInterestYearly: parseFloat(result?.futureInterestYearly * amount)
              }
              setTradeData(data)
            }

          }
          else {
            setTradeData(result)
          }
        }
      } else {
      }
    } catch (err) {
      console.log(err, 'errro');
    }
  };


  useEffect(() => {
    getStackinginfo()

  }, [priceConversion, defaultcurrency])

  return (
    <div className="dash_wrapper">
      <div className="row copy_trade_row">
        <div className="col-lg-12">
          <div className="dash_box trade_top_info">
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Total Earned Redemption</label>
              <label >{currencyChange(defaultcurrency)} <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.redemption, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Interest Paid</label>
              <label>{currencyChange(defaultcurrency)} <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.interest, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated APY Interest</label>
              <label>{currencyChange(defaultcurrency)} <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.futureInterestYearly, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated Total Interest</label>
              <label>{currencyChange(defaultcurrency)} <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.furueRedemption, 2)} /></label>
            </div>
            <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <label>Estimated Total Redemption</label>
              <label>{currencyChange(defaultcurrency)} <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(tradedata?.futureAmount, 2)} /></label>
            </div>
          </div>
        </div>


      </div>

    </div >
  );
};

export default StackedDetail;
