import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import CurrencyFormat from "react-currency-format";
import DataTable from "react-data-table-component";
// Images
// import copy_icon from "../assets/images/copy_icon.svg";

// import components
import Trade from "../components/Trade";
import { toFixedDown, truncateDecimals } from "../lib/roundOf";
import Marquee from "react-fast-marquee";
import PortfolioPerformance from "../components/Hedging/PortfolioPerformance.js";
import PortfolioAllocation from "../components/Hedging/PortfolioAllocation.js";
import TradingViewWidget from "../components/Hedging/header.js"
import SocketContext from "../context/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPairList, setPairList } from "../api/tradeAction.js";
import SeconChart from "../components/Hedging/secondChart.js"
import NewChart from "../components/Hedging/newChart.js"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Hedging(props) {



  //hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const socketContext = useContext(SocketContext);

  const [available, setAvailable] = useState(0);


  const pairListData = useSelector((state) => state.pairList);


  const [symbols, setSymbols] = useState([])
  const [duppairlists, setduppairlists] = useState([]);

  const [piechart, setPieChart] = useState([])
  const [piecurrency, setPieCurrency] = useState([])



  const fetchPairList = async () => {
    try {
      const { status, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
      }
    } catch (err) {
      console.log(err, "error");
    }

  };



  const columns = [
    {
      name: "Name",
      selector: (row) => <div className="market_pair">
        <img
          alt="img"
          className="img-fluid"
          src={row?.image}
        />
        <p>
          {row?.firstCurrencySymbol
            + "/" + row?.secondCurrencySymbol} <span>{row?.currencyName}</span>
        </p>
      </div>,
    },
    {
      name: "Quantity",
      selector: (row) => <span>{toFixedDown(row?.hedgeQty, 8)}</span>,
    },
    {
      name: "Price",
      selector: (row) => <> $ {toFixedDown(row?.markPrice, 8)}</>,
    },
    {
      name: "24H Change",
      selector: (row) => <span className={row?.change < 0 ? "change_badge badge_red" : "change_badge badge_green"}>{row?.change}%</span>
    },
    {
      name: "24H High",
      selector: (row) => <> $ {toFixedDown(row?.high, 8)}</>,
    },
    {
      name: "24H Low",
      selector: (row) => <> $ {toFixedDown(row?.low, 8)}</>,
    },
    {
      name: "Total",
      selector: (row) => <> $ {toFixedDown(row?.hedgeQty * row?.markPrice, 8)}</>,
    },
  ];


  useEffect(() => {
    fetchPairList();
  }, [])

  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      let array = []
      let curArray = []
      var newarray = [];
      let symbol = []
      let totalAmount = 0;

      for (var i = 0; i < pairListData.length; i++) {
        if (pairListData[i].hedging == true && pairListData[i].hedgeStatus == "active") {
          // console.log(pairListData[i], "-pairListData[i]")
          newarray.push(pairListData[i]);
          if (pairListData[i].type == 'crypto') {
            symbol.push({ proName: "BINANCE:" + pairListData[i].firstCurrencySymbol + pairListData[i].secondCurrencySymbol })
          }
          curArray.push(pairListData[i].firstCurrencySymbol)
          array.push(parseFloat(pairListData[i].hedgeQty) * parseFloat(pairListData[i].markPrice))
          totalAmount += parseFloat(pairListData[i].hedgeQty) * parseFloat(pairListData[i].markPrice)
        }
      }
      setSymbols(symbol)
      setAvailable(toFixedDown(totalAmount, 2))
      setPieChart(array)
      setPieCurrency(curArray)
      setduppairlists(newarray);
    }
  }, [pairListData]);


  useEffect(() => {
    let pathname = location.pathname;
    if (pathname == "/hedging") {
      socketContext.socket.on("marketPrice", (result) => {
        // console.log(result,"-------result")
        setduppairlists((el) => {
          let pairList = [];
          el.map((item) => {
            if (item._id == result.pairId) {
              pairList.push({
                ...item,
                ...{
                  markPrice: result.data.markPrice,
                  change: result.data.change,
                },
              });
            } else {
              pairList.push(item);
            }
          });

          return pairList;
        });
      });
    }
  }, []);


  // console.log(available, "-available")

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container-fluid">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_box">
                  <TradingViewWidget symbols={symbols} />
                  {/* <Marquee>
                    {duppairlists && duppairlists.length > 0 && duppairlists.map((item, key) => {
                      return (
                        <div className="price_ticker">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              alt="img"
                              className="img-fluid"
                              src={item?.image}
                            />
                            <p>{item?.currencyName}</p>
                          </div>
                          <h5>{item?.markPrice}</h5>
                          <h5 className={item?.change > 0 ? "text_green" : "text_red"}>{item?.changePrice} ({item?.change}%)</h5>
                        </div>
                      )
                    })}
                  </Marquee> */}

                  {/* <div className="price_ticker">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          alt="img"
                          className="img-fluid"
                          src={require("../assets/images/crypto/eth.png")}
                        />
                        <p>Ethereum</p>
                      </div>
                      <h5>3489.20</h5>
                      <h5 className="text_green">+2.54 (+0.15%)</h5>
                    </div>

                    <div className="price_ticker">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          alt="img"
                          className="img-fluid"
                          src={require("../assets/images/crypto/ltc.png")}
                        />
                        <p>Litecoin</p>
                      </div>
                      <h5>74.81</h5>
                      <h5 className="text_red">-2.54 (-0.15%)</h5>
                    </div>

                    <div className="price_ticker">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          alt="img"
                          className="img-fluid"
                          src={require("../assets/images/crypto/bnb.png")}
                        />
                        <p>BNB</p>
                      </div>
                      <h5>350.81</h5>
                      <h5 className="text_red">-2.54 (-0.15%)</h5>
                    </div>

                    <div className="price_ticker">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          alt="img"
                          className="img-fluid"
                          src={require("../assets/images/crypto/trx.png")}
                        />
                        <p>Tron</p>
                      </div>
                      <h5>14.81</h5>
                      <h5 className="text_red">-2.54 (-0.15%)</h5>
                    </div>

                    <div className="price_ticker">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          alt="img"
                          className="img-fluid"
                          src={require("../assets/images/crypto/doge.png")}
                        />
                        <p>Dogecoin</p>
                      </div>
                      <h5>12.81</h5>
                      <h5 className="text_green">+2.54 (+0.15%)</h5>
                    </div> */}

                </div>
                <div className="dash_box trade_top_info">
                  {/* <div> */}
                  <h3>Total Hedge Fund Portfolio Value: </h3>
                  <h3 style={{ color: available >= 0 ? "#00cdf9" : "#dc3545" }}>
                    ${" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={available}
                    />
                  </h3>
                  {/* </div> */}
                  {/* <div>
                    <label >Cash Balance</label>
                    <label>
                      ${" "}
                      <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={available}
                      /> 
                    </label>
                  </div> */}
                  {/* <div>
                    <label>Equity</label>
                    <label className={25 >= 0 ? "txt_success" : "txt_red"}>
                      ${" "}
                      <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={truncateDecimals(23, 2)}
                      />
                    </label>
                  </div> */}
                  {/* <div>
                    <label>Used Margin</label>
                    <label>
                      ${" "}
                      <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={available?.lockedBal}
                      />
                    </label>
                  </div> */}
                  {/* <div>
                    <label>Available Margin</label>
                    <label>
                      ${" "}
                      <CurrencyFormat
                        displayType={"text"}
                        thousandSeparator={true}
                        value={available?.totalBal}
                      />
                    </label>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-7 d-flex">
                    <div className="dash_box w-100">
                      <div class="orderbook_header">
                        <h6 class="spot_head">Portfolio Performance</h6>
                      </div>
                      {/* <SeconChart  piechart={piechart} piecurrency={piecurrency} /> */}
                      {/* <NewChart /> */}
                      <PortfolioPerformance  />
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="dash_box w-100">
                      <div class="orderbook_header">
                        <h6 class="spot_head">Portfolio Allocation</h6>
                      </div>
                      {/* <PortfolioPerformance /> */}
                      <SeconChart piechart={piechart} piecurrency={piecurrency} />
                      {/* <PortfolioAllocation piechart={piechart} piecurrency={piecurrency} /> */}
                    </div>
                  </div>
                </div>
                <div className="dash_box">
                  <div className="primary_datatable">
                    <DataTable columns={columns} data={duppairlists} pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
